import React from "react"
import { Link } from "gatsby"
import { css } from "@emotion/core"

import SEO from "../../components/seo"
import Layout from "../../components/layout"
import CustomNavTitle from "../../components/CustomNavTitle"

import { theme } from "../../styles/theme"

import warningImage from "../../images/icons/icon-warning.svg"

const Assessment = ({ location }) => {
  return (
    <Layout>
      <SEO title="TACO Risk Assessment" />

      <CustomNavTitle
        title="TACO Risk Assessment"
        destination="/taco/assessment"
        location={location}
      />

      <article className="mt-gap-2" css={theme.components.row}>
        <header css={styles.header}>
          <img
            src={warningImage}
            css={styles.image}
            alt={`Warning Icon`}
            width={120}
            height={120}
          />
          <div>
            <h2>Steps to manage TACO Risk</h2>
            <p css={styles.subHeading}>
              If you answered <strong>YES</strong> to any of the risk assessment
              questions:
            </p>
          </div>
        </header>

        <ol css={styles.list}>
          <li>
            <h3 css={styles.stepNumber}>Step 1</h3>
            <p>
              Review the need for transfusion (do the benefits outweigh the
              risks?)
            </p>
          </li>
          <li>
            <h3 css={styles.stepNumber}>Step 2</h3>
            <p>
              Can the transfusion be safely deferred until the issue can be
              investigated, treated or resolved?
            </p>
          </li>
          <li className="theme">
            <h3 css={styles.stepNumber}>Step 3</h3>
            <ul>
              <li>
                Consider body weight dosing of red cells; especially if low
                body weight.
              </li>
              <li>
                Transfuse 1 unit (red cells) at a time and review symptoms of
                anaemia.
              </li>
              <li>Measure the fluid balance.</li>
              <li>Consider giving a prophylactic diuretic.</li>
              <li>Monitor vital signs closely, including O₂ saturations.</li>
            </ul>
          </li>
        </ol>

        <Link to="/taco/" css={theme.components.button}>
          Start again
        </Link>
      </article>
    </Layout>
  )
}

const styles = {
  header: css({
    backgroundColor: theme.colours.lightGrey,
    display: "flex",
    alignItems: "center",
    color: theme.colours.red,
    marginBottom: theme.gapHalf,
    borderRadius: theme.borderRadius,
    padding: theme.gapHalf,
  }),
  image: css({
    marginRight: theme.gap,
    maxWidth: 120,
  }),
  subHeading: css({
    marginTop: theme.gapHalf,
    color: theme.colours.black,
  }),
  list: css({
    listStyle: "none",
    marginBottom: theme.gap,
  }),
  stepNumber: css({
    color: theme.colours.white,
    backgroundColor: theme.colours.red,
    padding: theme.gap,
    fontWeight: "bold",
    fontSize: theme.fzH3,
    marginBottom: theme.gapHalf,
    marginTop: theme.gap,
    "&+ p, &+ ul": {
      padding: `0 ${theme.gap} ${theme.gap} ${theme.gap}`,
    },
  }),
}

export default Assessment
